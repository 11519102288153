.references-container {
    display: flex;
    padding: 55px 0px 50px 0px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-top: 1px solid var(--Gray-Light-Medium, #b4bac2);
    border-bottom: 1px solid var(--Gray-Light-Medium, #b4bac2);
    background-color: #f5f5f5;
    background: url(../assets/img/references-background.png) no-repeat center center;
}

@media (max-width: 680px) {
    .references-container {
        align-self: initial;
        align-items: center;
        flex-wrap: wrap;
        max-width: 620px;
    }
}

.references-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    align-self: center;
    margin: 0 auto;
    font: 600 33px/120% Poppins, sans-serif;
}

@media (max-width: 680px) {
    .references-title {
        font-size: 24px;
    }
}

.references {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    padding: 0 60px;
}
@media (max-width: 991px) {
    .references {
        margin: 40px 0 10px;
        padding: 0 20px;
    }
}
.references-inner {
    width: 100%;
}
@media (max-width: 991px) {
    .references-inner {
        max-width: 100%;
    }
}
.references-list {
    display: flex;
    gap: 20px;
}
@media (max-width: 991px) {
    .references-list {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
    }
}
.client-reference {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(180, 186, 194, 1);
    background-color: #f5f5f5;
    color: var(--Gray-Dark-Medium, #3e404d);
    padding: 36px 40px;
    width: 100%;
}
@media (max-width: 991px) {
    .client-reference {
        margin-bottom: 20px;
    }
}

@media (max-width: 360px) {
    .client-reference {
        padding: 36px 15px;
    }
}

.client-info {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}
@media (max-width: 991px) {
    .client-info {
        flex-wrap: wrap;
    }
}
.client-image-wrapper {
    display: flex;
    gap: 15px;
    flex: 1;
    padding-right: 30px;
}
.client-image {
    width: 80px;
    object-fit: cover;
    object-position: center;
}
.client-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
}
.client-name {
    font: 23px/120% Poppins, sans-serif;
}

@media (max-width: 680px) {
    .client-name {
        font-size: 20px;
    }
}

.client-position {
    margin-top: 4px;
    font: 14px/142% Poppins, sans-serif;
}
.client-logo {
    width: 100px;
    object-fit: cover;
    object-position: center;
}
.client-testimonial {
    margin-top: 26px;
    font: 400 15px/22px Poppins, sans-serif;
}
@media (max-width: 991px) {
    .client-testimonial {
        width: 100%;
    }
}
