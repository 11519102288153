.hero-image-container {
    display: flex;
    padding: 60px 145px;
    padding-bottom: 62px;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    background: url(../assets/img/grey-background.svg) no-repeat;
    background-color: #f5f5f5;
    background-position: right top;
}

.hero-image-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    flex: 1 0 0;
}

.hero-image-right {
    max-width: 600px;
    min-width: 320px;
    width: 43vw;
    margin-right: 15px;
    margin-top: 9px;
}
.hero-image-right img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.hero-section {
    display: flex;
    flex-direction: column;
    color: var(--Gray-Dark-Medium, #3e404d);
    padding: 0 15px;
}

.text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.hero-title {
    font: 600 40px/100% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    text-align: left;
    max-width: 445px;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 45px;
}

.hero-description {
    margin-top: 37px;
    padding-right: 70px;
    padding-bottom: 62px;
    font: 400 16px Poppins, sans-serif;
}

.image {
    aspect-ratio: 1.54;
    object-fit: contain;
    width: 100%;
    flex-grow: 1;
}

@media (max-width: 991px) {
    .hero-image-container {
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        padding-left: 0;
        padding-right: 0;
    }

    .hero-image-right {
        margin-right: 0;
    }

    .hero-section {
        max-width: 100%;
    }

    .hero-title {
        margin-top: 30px;
    }

    .image {
        max-width: 100%;
    }
}

@media (max-width: 680px) {
    .text-content {
        align-items: start;
    }

    .hero-title {
        font-size: 26px;
        max-width: none;
        line-height: 30px;
    }

    .hero-description {
        margin-bottom: 36px;
    }

    .hero-image-container {
        padding: 70px 0px;
    }
}
