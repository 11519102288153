.header {
    align-items: center;
    border-bottom: 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 55px;
}

.header .header-navigation {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-around;
    position: relative;
    width: 100%;
}

.header .frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 24px;
    justify-content: space-around;
    position: relative;
}

.header .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
}

.header .frame-wrapper {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
}

.header .sq-LOGO-wrapper {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 65px;
}

.header .sq-LOGO,
.header .nav-link {
    height: 36.63px;
    position: relative;
    width: 53.94px;
}

.header .div-wrapper {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;
}

.header .frame-2 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 0px 9px;
    position: relative;
}

.header .icon-menu-wrapper {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: flex-end;
    position: relative;
}

.header .icon-menu {
    height: 34px !important;
    position: relative !important;
    width: 34px !important;
}

.header .header-navigation-2 {
    align-items: center;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #83848c;
    display: flex;
    height: 51px;
    justify-content: space-around;
    padding: 0px 16px;
    position: relative;
    width: 100%;
}

.header .frame-3 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
}

.header .img-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 9.22px;
    height: 47px;
    justify-content: center;
    position: relative;
    width: 59.9px;
}

.header .frame-4 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 1px;
    justify-content: center;
    padding: 16px 0px 16px 2px;
    position: relative;
}

.header .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    margin-bottom: -12.5px;
    margin-top: -12.5px;
    padding: 9px;
    position: relative;
}

.header .frame-6 {
    align-items: flex-start;
    display: flex;
    gap: 8.67px;
    height: 26px;
    position: relative;
    width: 26px;
}

.header .ellipse {
    border: 1.3px solid;
    border-color: #edf0f5;
    border-radius: 13px;
    height: 26px;
    position: relative;
    width: 26px;
}

.header .ellipse-2 {
    border: 1.3px solid;
    border-color: #edf0f5;
    border-radius: 6.93px/13px;
    height: 26px;
    left: 6px;
    position: absolute;
    top: 0;
    width: 14px;
}

.header .line {
    height: 26px;
    left: 13px;
    position: absolute;
    top: 0;
    width: 1px;
}

.header .img {
    height: 1px;
    left: 0;
    position: absolute;
    top: 12px;
    width: 26px;
}

.header .line-2 {
    height: 1px;
    left: 2px;
    position: absolute;
    top: 6px;
    width: 23px;
}

.header .line-3 {
    height: 1px;
    left: 2px;
    position: absolute;
    top: 18px;
    width: 23px;
}

.header .vector {
    height: 8.81px;
    position: relative;
    width: 16px;
}

.header .line-4 {
    height: 51px;
    left: 71px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1px;
}

.header .icon-menu-instance {
    height: 35px !important;
    position: relative !important;
    width: 35px !important;
}

.header .frame-7 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #2b2b2b;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #83848c;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    display: flex;
    flex: 0 0 auto;
    gap: 134px;
    justify-content: space-around;
    padding: 40px 16px 180px;
    position: relative;
    width: 100%;
    margin-bottom: -440px;
}

.header .frame-8 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 50px 40px;
    justify-content: space-around;
    position: relative;
}

.header .frame-9 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 160px 40px;
    position: relative;
}

.header .frame-10 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 73px 30px;
    position: relative;
}

.header .frame-11 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
}

.header .text-wrapper {
    color: #b4bac2;
    font-family: 'Poppins-Regular', Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17.9px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.header .frame-12 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
}

.header .frame-13 {
    align-items: center;
    display: flex;
    gap: 10px;
    height: 47px;
    position: relative;
    width: 245px;
}

.header .text-wrapper-2 {
    color: #edf0f5;
    font-family: 'Poppins-Regular', Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.header .frame-14 {
    align-items: center;
    display: flex;
    gap: 10px;
    height: 47px;
    position: relative;
    width: 90px;
}

.header .frame-15 {
    align-items: center;
    display: flex;
    gap: 10px;
    height: 47px;
    position: relative;
    width: 125px;
}

.header.default {
    background-color: #f5f5f5;
    border-color: #b4bac2;
    height: 51px;
    padding: 0px 16px;
}

.header.variant-2 {
    background-color: #2b2b2b;
    border-color: #83848c;
    height: auto;
}

.frame-8 {
    flex-direction: column;
}

.mobile-lang-switch {
    display: flex;
    justify-content: center;
    width: 100%;
}

.mobile-lang-items-wrapper {
    display: flex;
    gap: 50px;
}

.mobile-lang-item {
    color: white;
    font-weight: 300;
}

.mobile-lang-item-selected {
    text-decoration: underline;
    color: white;
    font-weight: 600;
}
