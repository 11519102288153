.careers-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* margin-bottom: 500px; */
}
.careers-hero-section {
    display: flex;
    justify-content: center;
    background: url(../assets/img/white-background.svg) no-repeat;
}
.careers-hero-content {
    display: flex;
    justify-content: center;
}

.hero-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 60px 51px;
}
@media (max-width: 991px) {
    .hero-info {
        max-width: 100%;
        padding: 0 20px;
    }
}
.hero-text-container {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 991px) {
    .hero-info {
        padding: 0 20px;
    }
}

.careers-hero-title {
    color: var(--Black-Black-900, #111110);
    text-align: center;
    font: 600 40px/100% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 70px;
    margin-bottom: 30px;
}
.careers-hero-description {
    color: var(--Gray-Dark-Medium, #3e404d);
    text-align: center;
    font: 400 16px Poppins, sans-serif;
    margin-top: 0px;
    width: 68%;
}
@media (max-width: 991px) {
    .careers-hero-description {
        max-width: 100%;
        margin-bottom: 50px;
    }
}
.job-section {
    border-top: 1px solid var(--Gray-Light-Medium, #b4bac2);
    width: 100%;
}
@media (max-width: 991px) {
    .job-section {
        max-width: 100%;
        padding-left: 20px;
    }
}
.job-container {
    display: flex;
    width: 100%;
}
@media (max-width: 991px) {
    .job-container {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
    }
}
.job-list {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 100%;
    margin-left: 0;
    flex-grow: 1;
    align-items: center;
}
@media (max-width: 991px) {
    .job-list {
        width: 100%;
    }
}
.job-list-inner {
    display: flex;
    flex-direction: column;
    /* padding: 55px 0 63px; */
    /* padding-left: 24px; */
    width: 100%;
    align-items: center;
}
@media (max-width: 991px) {
    .job-list-inner {
        max-width: 100%;
        margin-top: 40px;
    }
}
.job-list-content {
    display: flex;
    flex-direction: column;
    width: 61%;
}
@media (max-width: 991px) {
    .job-list-content {
        max-width: 100%;
    }
}
.job-content {
    display: flex;
    flex-direction: column;
    color: var(--Gray-Dark-Medium, #3e404d);
}
@media (max-width: 991px) {
    .job-content {
        max-width: 100%;
    }
}
.job-header {
    font: 600 33px/120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 56px;
    margin-bottom: 39px;
}
@media (max-width: 991px) {
    .job-header {
        max-width: 100%;
    }
}
.job-position-container {
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    gap: 31px;
    margin-top: 0;
}
@media (max-width: 991px) {
    .job-position-container {
        max-width: 100%;
        margin-right: 40px;
    }
}
.job-position {
    border: 1px solid rgba(62, 64, 77, 1);
    display: flex;
    gap: 15px;
    padding: 16px 50px;
    justify-content: space-between;
}
@media (max-width: 991px) {
    .job-position {
        flex-wrap: wrap;
        /* padding: 0 20px; */
    }
}
.job-title {
    font-family: Poppins, sans-serif;
}
.job-image {
    aspect-ratio: 0.6;
    object-fit: auto;
    object-position: center;
    width: 6px;
    fill: var(--Gray-Dark-Medium, #3e404d);
    margin: auto 0;
}
.contact-section {
    display: flex;
    margin-top: 51px;
    flex-direction: column;
    font-weight: 500;
}

.contact-text {
    color: var(--Gray-Dark-Medium, #3e404d);
    font: 20px Poppins, sans-serif;
    font-weight: 500;
    margin-bottom: 40px;
}

.careers-image-section {
    flex-shrink: 0;
    background: url(../assets/img/careers-hero.svg);
    flex-shrink: 0;
    /* width: 620px; */
    background-size: cover;
    width: 43vw;
    max-width: 620px;
}

.careers-hero-image {
    height: 635px;
    width: 100%;
}

.careers-nav-link {
    color: inherit;
    text-decoration: none;
    margin-bottom: 75px;
}

.job-pic {
    background-image: url(../assets/img/careers-mobile-pic.png);
    width: calc(100vw - 15px);
    min-height: calc(170 * 100vw / 680);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 26px;
    font-weight: 600;
}

@media (min-width: 992px) {
    .contact-text {
        max-width: 100%;
    }

    .job-pic {
        display: none;
    }
}

@media (max-width: 991px) {
    .contact-section {
        max-width: 100%;
        margin-top: 40px;
    }

    .careers-image-section {
        display: none;
    }
}

.contact-btn {
    display: flex;
    height: 56px;
    padding: 16px 38px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 100px;
    background: var(--Secondary-1, #646aff);
    color: white;
    margin-right: 40px;
    width: 183px;
}
