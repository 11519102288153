.header-text {
    color: var(--Gray-Dark-Medium, #3e404d);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
}
.header-text-small {
    color: var(--Gray-Dark-Medium, #3e404d);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.header-button {
    display: flex;
    width: 183px;
    height: 56px;
    padding: 16px 38px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 30px;
    background: var(--Secondary-1, #646aff);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.genspace-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.centered-content {
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    flex-direction: column;
}
@media (max-width: 991px) {
    .centered-content {
        max-width: 100%;
    }
}
.bordered-section {
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(180, 186, 194, 1);
    background-color: var(--Background, #f5f5f5);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 60px 70px;
}
@media (max-width: 991px) {
    .bordered-section {
        max-width: 100%;
        padding-left: 20px;
    }
}
.responsive-image {
    aspect-ratio: 2.7;
    object-fit: auto;
    object-position: center;
    width: 100%;
    max-width: 1279px;
}
@media (max-width: 991px) {
    .responsive-image {
        max-width: 100%;
    }
}
.highlight-section {
    align-items: center;
    border-top: 1px solid rgba(180, 186, 194, 1);
    background-color: var(--Background, #f5f5f5);
    display: flex;
    width: 100%;
    color: var(--Gray-Dark-Medium, #3e404d);
    justify-content: center;
    padding: 1px 0;
}
@media (max-width: 991px) {
    .highlight-section {
        max-width: 100%;
        padding-right: 20px;
    }
}
.content-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    /* min-height: 389px; */
    width: 100%;
    padding: 54px 0;
}
@media (max-width: 991px) {
    .content-wrapper {
        max-width: 100%;
    }
}
.genspace-background-image {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.section-title {
    position: relative;
    align-self: center;
    /* margin-left: 125px; */
    font: 600 33px/120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.advantage-section-title {
    margin-bottom: 54px;
    margin-top: 53px;
}

/* ------------------------------------- */
.advantage-list {
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    /* margin-top: 55px; */
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 0 60px;
    margin-bottom: 68px;
}
@media (max-width: 991px) {
    .section-title.advantage-section-title {
        font-size: 26px;
        margin-bottom: 35px;
        margin-top: 36px;
    }

    .advantage-list {
        max-width: 100%;
        /* margin-top: -20px; */
        padding: 20px;
    }
}
.advantage-group {
    display: flex;
    /* width: 930px; */
    max-width: 100%;
    flex-direction: column;
}
/* .advantage-section {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
}
@media (max-width: 680px) {
    .advantage-section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
} */

.advantage-section {
    display: grid;
    grid-gap: 84px;
    grid-row-gap: 60px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
        'box1 box1 box2 box2 box3 box3'
        'empt box4 box4 box5 box5 emp2';
    grid-template-rows: 1fr 1fr;
}

.advantage-section .advantage:nth-child(1) {
    grid-area: box1;
}

.advantage-section .advantage:nth-child(2) {
    grid-area: box2;
}

.advantage-section .advantage:nth-child(3) {
    grid-area: box3;
}

.advantage-section .advantage:nth-child(4) {
    grid-area: box4;
}

.advantage-section .advantage:nth-child(5) {
    grid-area: box5;
}

@media (max-width: 1100px) and (min-width: 551px) {
    .advantage-section {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
            'box1 box1 emp1 emp2 box2 box2'
            'box3 box3 emp3 emp4 box4 box4'
            'box5 box5 box5 box5 box5 box5';
        grid-template-rows: 1fr 1fr;
    }

    .advantage-section .advantage:nth-child(5) {
        grid-area: box5;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 550px) {
    .advantage-section {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}

.advantage-bottom {
    width: 80%;
    padding-top: 36px;
    padding-left: 20%;
}

.advantage {
    display: flex;
    gap: 20px;
}
.advantage-icon {
    aspect-ratio: 1;
    width: 55px;
}
.advantage-text {
    max-width: 180px;
    display: flex;
    align-items: center;
    font-family: Poppins, sans-serif;
    /* margin: auto 0;ś */
}

/* --------------------------------------- */

.image-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 590px;
    max-width: 100%;
    gap: 20px;
}
@media (max-width: 991px) {
    .image-section {
        flex-wrap: wrap;
        margin-top: 40px;
    }
}
.info-section {
    align-items: center;
    background-color: var(--Background, #f5f5f5);
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 33px;
    color: var(--Gray-Dark-Medium, #3e404d);
    font-weight: 600;
    padding: 0px 60px 27px;
}
@media (max-width: 991px) {
    .info-section {
        max-width: 100%;
        padding: 0 20px;
    }
}
.genspace-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 365px; */
    padding: 8px 16px 40px 16px;
}
@media (max-width: 680px) {
    .genspace-content-container {
        max-width: 100%;
        font-size: 26px;
    }
}
.section-heading {
    padding-top: 55px;
    font-family: Poppins, sans-serif;
}
.wide-image {
    aspect-ratio: 16.67;
    object-fit: auto;
    object-position: center;
    width: 100%;
    margin-top: 51px;
}

.wide-image-mobile {
    display: none;
}

@media (max-width: 680px) {
    .wide-image {
        aspect-ratio: auto;
    }
}
.two-column {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(180, 186, 194, 1);
    background-color: var(--Background, #f5f5f5);
    /* width: 100%; */
    max-width: calc(100vw - 15px);
    /* padding-left: 80px; */
}
@media (max-width: 680px) {
    .two-column {
        flex-direction: column-reverse;
    }
}
.column-left {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 70%;
    margin-left: 0;
}
@media (max-width: 991px) {
    .column-left {
        width: 100%;
    }

    .wide-image {
        display: none;
    }

    .wide-image-mobile {
        display: block;
        margin-top: 51px;
    }
}

.column-right {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 46.7%;
    /* margin-left: 20px; */
}

.section-block {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 23px; */
    align-items: center;
}
@media (max-width: 991px) {
    .section-block {
        margin-top: 40px;
    }
}
.genspace-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 74%;
    /* padding: 50px 0; */
    /* padding-left: 20px; */
}
.section-text {
    display: flex;
    flex-direction: column;
    color: var(--Gray-Dark-Medium, #3e404d);
    width: 67%;
    margin-top: 53px;
    margin-right: 66px;
}
.section-title-large {
    font: 600 40px/40px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    line-height: 45px;
}
.section-description {
    margin-top: 28px;
    font: 400 16px Poppins, sans-serif;
    line-height: 24px;
}
.action-button {
    justify-content: center;
    border-radius: 30px;
    background-color: var(--Secondary-1, #646aff);
    margin-top: 59px;
    color: #fff;
    padding: 16px 38px;
    font: 500 16px Poppins, sans-serif;
    width: 183px;
    align-items: center;
    display: flex;
    align-self: start;
}

@media (max-width: 991px) {
    .action-button {
        margin-top: 40px;
        padding: 16px 20px;
    }

    .genspace-section-content {
        align-items: center;
        padding-left: 0px;
    }
}

@media (max-width: 680px) {
    .section-title-large {
        font-size: 26px;
    }

    .column-right {
        width: 100%;
        height: 280px;
        /* margin-left: 20px; */
        overflow: hidden;
    }
}

@media (max-width: 1440px) {
    .genspace-section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    .section-text {
        margin-top: 20px;
        margin-right: 0px;
        width: 100%;
    }

    .action-button {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
