.header-text {
    color: var(--Gray-Dark-Medium, #3e404d);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
}
.header-text-small {
    color: var(--Gray-Dark-Medium, #3e404d);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.header-button {
    display: flex;
    width: 183px;
    height: 56px;
    padding: 16px 38px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 30px;
    background: var(--Secondary-1, #646aff);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.leasing-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
}
.leasing-section {
    align-items: center;
    border-top: 1px solid var(--Gray-Light-Medium, #b4bac2);
    border-bottom: 1px solid var(--Gray-Light-Medium, #b4bac2);
    background-color: var(--Background, #f5f5f5);
    width: 100%;
}

.leasing-no-border {
    border-bottom: none;
    border-top: none;
}

.content-container-leasing-how {
    border-top: 1px solid var(--Gray-Light-Medium, #b4bac2);
}

.leasing-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.content-container-leasing-how {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.content-container-leasing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
@media (max-width: 991px) {
    .leasing-content-container {
        max-width: 100%;
    }

    .content-container-leasing {
        max-width: 100%;
        /* padding-bottom: 60px; */
    }
}
.leasing-section-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    font: 600 33px / 120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 56px;
    margin-bottom: 64px;
}
.step-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 69px;
}
.step {
    width: 25%;
    text-align: center;
    margin-bottom: 40px;
}
@media (max-width: 991px) {
    .step {
        width: 100%;
    }
}
.step-title {
    font: 500 20px Poppins, sans-serif;
    margin-top: 15px;
}
.step-description {
    font: 400 14px Poppins, sans-serif;
    margin-top: 15px;
}
.step-image {
    width: 70px;
    margin-top: 33px;
}
.feature-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
}
.feature {
    width: 30%;
    text-align: center;
    margin-bottom: 40px;
}
@media (max-width: 991px) {
    .feature {
        width: 100%;
    }
}
.feature-title {
    font: 500 20px Poppins, sans-serif;
    margin-top: 15px;
}
.feature-description {
    font: 400 14px Poppins, sans-serif;
    margin-top: 15px;
}
.feature-image {
    width: 69px;
    margin-top: 32px;
}
.leasing-advantages-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
    column-gap: 13.5vw;
    margin-bottom: 46px;
}

.leasing-advantage {
    display: flex;
    gap: 20px;
    max-width: 245px;
}

.leasing-advantage:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
}

.leasing-advantage:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
}

.leasing-advantage:nth-child(3) {
    grid-column: 2;
    grid-row: 1;
}

.leasing-advantage:nth-child(4) {
    grid-column: 2;
    grid-row: 2;
}

.leasing-advantage:nth-child(5) {
    grid-column: 3;
    grid-row: 1;
}

.leasing-advantage:nth-child(6) {
    grid-column: 3;
    grid-row: 2;
}

@media (max-width: 1050px) {
    .leasing-advantages-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
        column-gap: 30px;
        width: 80vw;
    }

    .leasing-advantage {
        width: 90%;
    }

    .leasing-advantage:nth-child(1) {
        grid-column: 2;
        grid-row: 3;
    }

    .leasing-advantage:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
    }

    .leasing-advantage:nth-child(3) {
        grid-column: 2;
        grid-row: 2;
    }

    .leasing-advantage:nth-child(4) {
        grid-column: 1;
        grid-row: 3;
    }

    .leasing-advantage:nth-child(5) {
        grid-column: 1;
        grid-row: 2;
    }

    .leasing-advantage:nth-child(6) {
        grid-column: 1;
        grid-row: 1;
    }
}
.leasing-advantage-description {
    font: 400 16px Poppins, sans-serif;
}
.leasing-advantage-image {
    aspect-ratio: 1.2;
    width: 53px;
}
.section-with-bg {
    position: relative;
    overflow: hidden;
}
.bg-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.bg-content {
    position: relative;
}
.steps-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    font: 600 33px/120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
.leasing-advantage-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    font: 600 33px/120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 56px;
    margin-bottom: 46px;
}

.how-it-works-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    font: 600 33px/120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-bottom: 46px;
    margin-top: 56px;
}

.how-it-works-image {
    aspect-ratio: 16.67;
    width: 77%;
    margin-bottom: 90px;
}

.how-it-works-image-mobile {
    display: none;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}

.leasing-process-image {
    max-width: 90vw;
}

.leasing-process-image-mobile {
    display: none;
}

@media (max-width: 991px) {
    .leasing-section {
        max-width: 100%;
        padding: 0 20px 50px 20px;
    }

    .how-it-works-image {
        display: none;
    }

    .how-it-works-image-mobile {
        display: block;
    }

    .leasing-process-image {
        display: none;
    }

    .leasing-process-image-mobile {
        display: block;
        width: 50vw;
        min-width: 350px;
    }
}

@media (max-width: 680px) {
    .how-it-works-title {
        font-size: 26px;
    }

    .how-it-works-image {
        aspect-ratio: unset;
        width: unset;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .section {
        max-width: 100%;
        padding: 0 0px;
    }

    .leasing-advantages-container {
        grid-template-columns: repeat(1, 1fr);
        width: 80vw;
        justify-items: center;
    }

    .leasing-advantage {
        max-width: 300px;
    }

    .leasing-advantage:nth-child(1) {
        grid-column: 1;
        grid-row: 6;
    }

    .leasing-advantage:nth-child(2) {
        grid-column: 1;
        grid-row: 4;
    }

    .leasing-advantage:nth-child(3) {
        grid-column: 1;
        grid-row: 5;
    }

    .leasing-advantage:nth-child(4) {
        grid-column: 1;
        grid-row: 3;
    }

    .leasing-advantage:nth-child(5) {
        grid-column: 1;
        grid-row: 2;
    }

    .leasing-advantage:nth-child(6) {
        grid-column: 1;
        grid-row: 1;
    }
}
