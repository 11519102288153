:root {
    --b1-font-size: 20px;
    --b1-font-style: normal;
    --b1-font-weight: 500;
    --b1-letter-spacing: 0px;
    --b1-line-height: normal;
    --background: rgba(245, 245, 245, 1);
    --basicwhite: rgba(255, 255, 255, 1);
    --collection-1-background: rgba(255, 255, 255, 1);
    --collection-1-background-dark: rgba(230, 230, 231, 1);
    --collection-1-header-highlight: rgba(121, 135, 255, 1);
    --collection-1-header-highlighted: rgba(115, 129, 255, 1);
    --collection-1-secondary: rgba(93, 111, 241, 1);
    --collection-1-secondary-1: rgba(100, 106, 255, 1);
    --collection-1-secondary-3: rgba(149, 154, 255, 1);
    --collection-1-secondary-4: rgba(166, 186, 255, 1);
    --graydark-medium: rgba(62, 64, 77, 1);
    --graylight-medium: rgba(180, 186, 194, 1);
    --graymedium: rgba(131, 133, 140, 1);
    --h1-font-size: s44px;
    --h1-font-style: normal;
    --h1-font-weight: 600;
    --h1-letter-spacing: 0px;
    --h1-line-height: 100%;
    --h2-font-size: s33px;
    --h2-font-style: normal;
    --h2-font-weight: 600;
    --h2-letter-spacing: 0px;
    --h2-line-height: 120.00000476837158%;
    --h3-font-size: s28px;
    --h3-font-style: normal;
    --h3-font-weight: 600;
    --h3-letter-spacing: 0px;
    --h3-line-height: 120.00000476837158%;
    --h4-font-size: s23px;
    --h4-font-style: normal;
    --h4-font-weight: 600;
    --h4-letter-spacing: 0px;
    --h4-line-height: 120.00000476837158%;
    --p1-font-size: s15px;
    --p1-font-style: normal;
    --p1-font-weight: 400;
    --p1-letter-spacing: 0px;
    --p1-line-height: normal;
    --p2-font-size: s14px;
    --p2-font-style: normal;
    --p2-font-weight: 400;
    --p2-letter-spacing: 0px;
    --p2-line-height: 17.926006317138672px;
    --p3-font-size: s14px;
    --p3-font-style: normal;
    --p3-font-weight: 600;
    --p3-letter-spacing: 0px;
    --p3-line-height: 19.917774200439453px;
    --p5-font-size: s18px;
    --p5-font-style: normal;
    --p5-font-weight: 400;
    --p5-letter-spacing: 0px;
    --p5-line-height: normal;
    --secondary-1: var(--collection-1-secondary-1);
    --secondary-2: rgba(131, 136, 255, 1);
    --secondary-3: rgba(80, 85, 204, 1);
    --secondary-4: rgba(180, 186, 194, 1);
}
.benefits-title-container {
    display: flex;
    max-width: 1440px;
    height: 446px;
    padding: 60px 160px 80px 160px;
    flex-direction: column;
    align-items: flex-start;
}
.benefits-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

@media (max-width: 680px) {
    .benefits-title-container {
        max-width: 680px;
        height: min-content;
        padding: 20px 20px;
        gap: 20px;
    }

    .benefits-title {
        font-size: 24px;
    }
}

.benefits-responsive-image {
    aspect-ratio: 3.03;
    object-fit: auto;
    object-position: center;
    width: 100%;
}
@media (max-width: 680px) {
    .benefits-responsive-image {
        max-width: 680px;
    }
}

.benefits {
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 55px 160px;
    position: relative;
    max-width: 1440px;
    border-top: solid 1px #b4bac2;
}

@media (max-width: 680px) {
    .benefits {
        align-items: center;
        flex-wrap: wrap;
        padding: 55px 20px;
        /* width: min-content; */
    }
}

.benefits .text-wrapper {
    color: var(--graydark-medium);
    font-size: svar(--h2-font-size);
    font-style: var(--h2-font-style);
    font-weight: var(--h2-font-weight);
    letter-spacing: var(--h2-letter-spacing);
    line-height: var(--h2-line-height);
    margin-top: -1px;
    position: relative;
    /* white-space: nowrap; */
    width: fit-content;
    font-size: 32px;
}

@media (max-width: 680px) {
    .benefits .text-wrapper {
        font-size: 24px;
    }
}

.benefits .frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: 100%;
}

.benefits .benefits-header {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
}

.benefits .paragraph-wrapper {
    align-items: center;
    border: 1px solid;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    height: 70px;
    justify-content: center;
    margin-bottom: -1px;
    margin-left: -1px;
    margin-top: -1px;
    padding: 19px 25px;
    position: relative;
    width: 100%;
}

.benefits .paragraph {
    flex: 1;
    font-size: var(--b1-font-size);
    font-style: var(--b1-font-style);
    font-weight: var(--b1-font-weight);
    letter-spacing: var(--b1-letter-spacing);
    line-height: var(--b1-line-height);
    margin-top: -0.5px;
    position: relative;
    text-align: center;
}

@media (min-width: 1100px) and (max-width: 1190px) {
    .benefits .paragraph-wrapper .paragraph {
        font-size: 18px; /** for polish lang too long */
    }
}

.benefits .div-wrapper {
    align-items: center;
    border: 1px solid;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    height: 70px;
    justify-content: center;
    margin-bottom: -1px;
    margin-top: -1px;
    padding: 19px 25px;
    position: relative;
    width: 100%;
}

.benefits .paragraph-2 {
    font-size: var(--b1-font-size);
    font-style: var(--b1-font-style);
    font-weight: var(--b1-font-weight);
    letter-spacing: var(--b1-letter-spacing);
    line-height: var(--b1-line-height);
    margin-top: -0.5px;
    position: relative;
}

.benefits .frame-2 {
    align-items: center;
    border: 1px solid;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    height: 70px;
    justify-content: center;
    margin-bottom: -1px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 19px 25px;
    position: relative;
    width: 100%;
}

.benefits .paragraph-3 {
    flex: 1;
    font-size: var(--b1-font-size);
    font-style: var(--b1-font-style);
    font-weight: var(--b1-font-weight);
    letter-spacing: var(--b1-letter-spacing);
    line-height: var(--b1-line-height);
    margin-top: -0.5px;
    position: relative;
    text-align: center;
}

.benefits .frame-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
}

.benefits .frame-wrapper {
    align-self: stretch;
    background-color: #f5f5f5;
    border: 1px solid;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    padding: 32px 24px 40px;
    position: relative;
}

@media (max-width: 1100px) {
    .benefits .frame {
        align-items: center;
        justify-self: center;
        justify-content: center;
    }
    .benefits .benefits-header {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: auto;
    }
    .benefits .benefits-header div {
        /* max-width: 598px; */
    }

    .frame-3 {
        flex-direction: column;
    }
}

@media (max-width: 680px) {
    .benefits .frame-wrapper {
        height: 100px;
        padding: 24px 16px;
        flex: auto;
    }
    .benefits .frame-5 {
        height: 100px;
        padding: 24px 16px;
        flex: auto;
    }
    .benefits .frame-6 {
        height: 100px;
        padding: 24px 16px;
        flex: auto;
    }

    .benefits .paragraph-5 {
        font-size: 14px !important;
    }

    .benefits .frame-4 {
        gap: 0px !important;
    }
}

.benefits .frame-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
}

.benefits .paragraph-4 {
    align-self: stretch;
    color: var(--graydark-medium);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28.8px;
    margin-top: -1px;
    position: relative;
}

@media (max-width: 680px) {
    .benefits .paragraph-4 {
        flex: auto;
        font-size: 16px;
    }

    .benefits .paragraph-5 {
        font-size: 14px;
    }
}

.benefits .paragraph-5 {
    align-self: stretch;
    color: var(--graydark-medium);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
}

.benefits .frame-5 {
    align-self: stretch;
    background-color: #f5f5f5;
    border: 1px solid;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: -1px;
    margin-top: -1px;
    padding: 32px 24px 40px;
    position: relative;
}

.benefits .frame-6 {
    align-self: stretch;
    background-color: #f5f5f5;
    border: 1px solid;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    margin-bottom: -1px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 32px 24px 40px;
    position: relative;
}

.benefits .variant-8 {
    background-color: var(--collection-1-background-dark);
    border-color: var(--graylight-medium);
}

.benefits .variant-4 {
    background-color: var(--collection-1-background-dark);
    border-color: var(--graylight-medium);
}

.benefits .frame-3724 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .frame-3720 {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
}

.benefits .variant-9 {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
}

.benefits .variant-5 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .frame-3725 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .variant-6 {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
}

.benefits .variant-7 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .property-1-0-variant-8 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .property-1-0-variant-4 {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
}

.benefits .property-1-0-frame-3724 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .property-1-0-frame-3720 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .property-1-0-variant-9 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .property-1-0-variant-5 {
    background-color: var(--collection-1-background-dark);
    border-color: var(--graylight-medium);
}

.benefits .property-1-0-frame-3725 {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
}

.benefits .property-1-0-variant-6 {
    background-color: var(--collection-1-background-dark);
    border-color: var(--graylight-medium);
}

.benefits .property-1-0-variant-7 {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
}

.benefits .property-1-2-variant-8 {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
}

.benefits .property-1-2-variant-4 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .property-1-2-frame-3724 {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
}

.benefits .property-1-2-frame-3720 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .property-1-2-variant-9 {
    background-color: var(--collection-1-background-dark);
    border-color: var(--graylight-medium);
}

.benefits .property-1-2-variant-5 {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
}

.benefits .property-1-2-frame-3725 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .property-1-2-variant-6 {
    background-color: #f5f5f5;
    border-color: var(--graylight-medium);
}

.benefits .property-1-2-variant-7 {
    background-color: var(--collection-1-background-dark);
    border-color: var(--graylight-medium);
}

.benefits .property-1-4-variant-8 {
    justify-content: center;
}

.benefits .property-1-4-variant-4 {
    justify-content: center;
}

.benefits .property-1-4-frame-3724 {
    justify-content: center;
}

.benefits .property-1-4-frame-3720 {
    justify-content: center;
}

.benefits .property-1-4-variant-9 {
    justify-content: center;
}

.benefits .property-1-4-variant-5 {
    justify-content: center;
}

.benefits .property-1-4-variant-6 {
    justify-content: center;
}

.benefits .property-1-4-variant-7 {
    justify-content: center;
}

.benefits .paragraph-wrapper.variant-8 .paragraph {
    color: var(--graydark-medium);
}

.benefits .paragraph-wrapper.variant-4 .paragraph {
    color: var(--graydark-medium);
}

.benefits .paragraph-wrapper.frame-3724 .paragraph {
    color: var(--graymedium);
}

.benefits .paragraph-wrapper.frame-3720 .paragraph {
    color: var(--basicwhite);
}

.benefits .paragraph-wrapper.variant-9 .paragraph {
    color: var(--basicwhite);
}

.benefits .paragraph-wrapper.variant-5 .paragraph {
    color: var(--graymedium);
}

.benefits .paragraph-wrapper.frame-3725 .paragraph {
    color: var(--graymedium);
}

.benefits .paragraph-wrapper.variant-6 .paragraph {
    color: var(--basicwhite);
}

.benefits .paragraph-wrapper.variant-7 .paragraph {
    color: var(--graymedium);
}

.benefits .div-wrapper.property-1-0-variant-8 .paragraph-2 {
    color: var(--graymedium);
    flex: 1;
    text-align: center;
}

.benefits .div-wrapper.property-1-0-variant-4 .paragraph-2 {
    color: var(--basicwhite);
    flex: 1;
    text-align: center;
}

.benefits .div-wrapper.property-1-0-frame-3724 .paragraph-2 {
    color: var(--graymedium);
    flex: 1;
    text-align: center;
}

.benefits .div-wrapper.property-1-0-frame-3720 .paragraph-2 {
    color: var(--graymedium);
    flex: 1;
    text-align: center;
}

.benefits .div-wrapper.property-1-0-variant-9 .paragraph-2 {
    color: var(--graymedium);
    flex: 1;
    text-align: center;
}

.benefits .div-wrapper.property-1-0-variant-5 .paragraph-2 {
    color: var(--graydark-medium);
    flex: 1;
    text-align: center;
}

.benefits .div-wrapper.property-1-0-frame-3725 .paragraph-2 {
    color: var(--basicwhite);
    width: fit-content;
}

.benefits .div-wrapper.property-1-0-variant-6 .paragraph-2 {
    color: var(--graydark-medium);
    flex: 1;
    text-align: center;
}

.benefits .div-wrapper.property-1-0-variant-7 .paragraph-2 {
    color: var(--basicwhite);
    flex: 1;
    text-align: center;
}

.benefits .frame-2.property-1-2-variant-8 .paragraph-3 {
    color: var(--basicwhite);
}

.benefits .frame-2.property-1-2-variant-4 .paragraph-3 {
    color: var(--graymedium);
}

.benefits .frame-2.property-1-2-frame-3724 .paragraph-3 {
    color: var(--basicwhite);
}

.benefits .frame-2.property-1-2-frame-3720 .paragraph-3 {
    color: var(--graymedium);
}

.benefits .frame-2.property-1-2-variant-9 .paragraph-3 {
    color: var(--graydark-medium);
}

.benefits .frame-2.property-1-2-variant-5 .paragraph-3 {
    color: var(--basicwhite);
}

.benefits .frame-2.property-1-2-frame-3725 .paragraph-3 {
    color: var(--graymedium);
}

.benefits .frame-2.property-1-2-variant-6 .paragraph-3 {
    color: var(--graymedium);
}

.benefits .frame-2.property-1-2-variant-7 .paragraph-3 {
    color: var(--graydark-medium);
}

.benefits .frame-3.property-1-4-variant-8 .frame-wrapper {
    align-items: flex-start;
    border-color: var(--graylight-medium);
    gap: 10px;
}

.benefits .frame-3.property-1-4-variant-4 .frame-wrapper {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-frame-3724 .frame-wrapper {
    align-items: flex-start;
    border-color: var(--graylight-medium);
    gap: 10px;
}

.benefits .frame-3.property-1-4-frame-3720 .frame-wrapper {
    align-items: flex-start;
    border-color: var(--graylight-medium);
    gap: 10px;
}

.benefits .frame-3.property-1-4-variant-9 .frame-wrapper {
    align-items: flex-start;
    border-color: var(--graylight-medium);
    gap: 10px;
}

.benefits .frame-3.property-1-4-variant-5 .frame-wrapper {
    align-items: flex-start;
    border-color: var(--graylight-medium);
    gap: 10px;
}

.benefits .frame-3.property-1-4-frame-3725 .frame-wrapper {
    align-items: center;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-6 .frame-wrapper {
    align-items: flex-start;
    border-color: var(--graylight-medium);
    gap: 10px;
}

.benefits .frame-3.property-1-4-variant-7 .frame-wrapper {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-8 .frame-5 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-4 .frame-5 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
    gap: 10px;
}

.benefits .frame-3.property-1-4-frame-3724 .frame-5 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-frame-3720 .frame-5 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-9 .frame-5 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-5 .frame-5 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-frame-3725 .frame-5 {
    align-items: center;
    border-color: var(--graylight-medium);
    gap: 10px;
}

.benefits .frame-3.property-1-4-variant-6 .frame-5 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-7 .frame-5 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
    gap: 10px;
}

.benefits .frame-3.property-1-4-variant-8 .frame-6 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-4 .frame-6 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-frame-3724 .frame-6 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-frame-3720 .frame-6 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-9 .frame-6 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-5 .frame-6 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-frame-3725 .frame-6 {
    align-items: center;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-6 .frame-6 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-7 .frame-6 {
    align-items: flex-start;
    border-color: var(--graylight-medium);
}

.benefits .frame-3.property-1-4-variant-8 .paragraph-5 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}

.benefits .frame-3.property-1-4-variant-4 .paragraph-5 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}

.benefits .frame-3.property-1-4-frame-3724 .paragraph-5 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}

.benefits .frame-3.property-1-4-frame-3720 .paragraph-5 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}

.benefits .frame-3.property-1-4-variant-9 .paragraph-5 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}

.benefits .frame-3.property-1-4-variant-5 .paragraph-5 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}

.benefits .frame-3.property-1-4-frame-3725 .paragraph-5 {
    font-size: var(--p1-font-size);
    font-style: var(--p1-font-style);
    font-weight: var(--p1-font-weight);
    letter-spacing: var(--p1-letter-spacing);
    line-height: var(--p1-line-height);
}

.benefits .frame-3.property-1-4-variant-6 .paragraph-5 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}

.benefits .frame-3.property-1-4-variant-7 .paragraph-5 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}
