.management-main-container {
    width: 100%;
}

.management-process {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--Gray-Light-Medium, #b4bac2);
}

.management-process__title {
    color: var(--Gray-Dark-Medium, #3e404d);
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-top: 60px;
    margin-bottom: 69px;
}

.management-process__steps {
    margin-bottom: 72px;
}

.advantages {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgba(180, 186, 194, 1);
    background-color: var(--Background, #f5f5f5);
    width: 100%;
    min-height: 410px;
    position: relative;
}

.advantages__bg-image {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.advantages__title {
    position: relative;
    color: var(--Gray-Dark-Medium, #3e404d);
    font: 600 33px/120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 56px;
}

.advantages__content {
    position: relative;
    width: 85%;
    margin: 55px 0 10px;
    display: flex;
    row-gap: 50px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.advantages__item {
    display: flex;
    align-items: flex-start;
    width: 31%;
    flex-grow: 1;
    margin-right: 20px;
}

.advantages__item img {
    width: 50px;
    margin-right: 20px;
}

.advantages__item-text {
    font-family: Poppins, sans-serif;
}

.management-process-image {
    width: 74vw;
}

.management-process-image-mobile {
    display: none;
}

@media (max-width: 991px) {
    .advantages {
        padding-right: 20px;
        padding-left: 20px;
    }

    .advantages__content {
        margin-top: 40px;
        gap: 0;
        flex-direction: column;
        align-items: center;
    }

    .advantages__item {
        max-width: 100%;
        margin-top: 40px;
    }

    .management-process-image {
        display: none;
    }

    .management-process-image-mobile {
        display: block;
        min-width: 350px;
        width: 55vw;
    }
}
