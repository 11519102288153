.contact-container {
    display: flex;
    gap: 90px;
    background-color: var(--Background, #f5f5f5);
    padding: 70px 160px;
    color: var(--Gray-Dark-Medium, #3e404d);
}

@media (max-width: 1000px) {
    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        padding: 40px 20px;
        width: 100%;
    }
}

.form-grup-wrapper {
    display: flex;
    gap: 20px;
}
.left-col {
    flex: 1;
}

.heading {
    font: 600 40px/100% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    color: var(--Gray-Dark-Medium, #3e404d);
}

@media (max-width: 680px) {
    .heading {
        font-size: 24px;
    }
}

.subheading {
    margin-top: 40px;
    font: 400 16px Poppins, sans-serif;
    color: var(--Gray-Dark-Medium, #3e404d);
}
.right-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 20px;
    padding-top: 80px;
}
@media (max-width: 991px) {
    .right-col {
        margin-left: 0;
        padding-top: 40px;
        width: 100%;
        max-width: 480px;
    }
}
.form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.input,
.textarea {
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid rgba(180, 186, 194, 1);
    background-color: var(--Background, #f5f5f5);
}
.textarea {
    resize: none;
    height: 160px;
}
.products,
.consent {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.checkbox-group {
    display: flex;
    align-items: center;
    gap: 10px;
}
.submit-button {
    width: 171px;
    height: 54px;
    background-color: var(--Secondary-1, #646aff);
    color: #ffffff;
    font: 500 16px Poppins, sans-serif;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

#hubspot-err-text {
    margin-top: 70px;
    color: red;
    font-size: 16px;
}
