.team-main-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; */

    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(../assets/img/grey-background.svg) no-repeat;
    background-color: #f5f5f5;
    background-position: right top;
    background-position-y: -1px;
    width: 100%;
}

.team-inner-container {
    max-width: 1160px;
    padding: 0 20px;
}

.partner-placeholder {
    height: 44px;
    width: 44px;
}

.team-image-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px 80px;
    background-color: var(--Background, #f5f5f5);
    border-bottom: 1px solid rgba(180, 186, 194, 1);
    padding-left: 120px;
    padding-right: 0;
}
@media (max-width: 991px) {
    .image-section {
        padding-left: 20px;
    }
}
.responsive-image {
    width: 100%;
    max-width: 1279px;
    object-fit: auto;
    object-position: center;
    aspect-ratio: 1.25;
}
@media (max-width: 991px) {
    .responsive-image {
        max-width: 100%;
    }
}
.main-container {
    display: flex;
    flex-direction: column;
}
.image-wrapper {
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(180, 186, 194, 1);
    background-color: var(--Background, #f5f5f5);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 60px 80px;
}
@media (max-width: 991px) {
    .image-wrapper {
        max-width: 100%;
        padding-left: 20px;
    }
}
.main-image {
    aspect-ratio: 1.25;
    object-fit: auto;
    object-position: center;
    width: 100%;
}
@media (max-width: 991px) {
    .main-image {
        max-width: 100%;
    }
}
.content-wrapper {
    align-items: center;
    border-bottom: 1px solid rgba(180, 186, 194, 1);
    background-color: var(--Background, #f5f5f5);
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1px 0;
}
@media (max-width: 991px) {
    .content-wrapper {
        max-width: 100%;
        padding-right: 20px;
    }
}

/* .careers-hero-section {
    display: flex;
    justify-content: center;
    background: url(../assets/img/white-background.svg) no-repeat;
} */

.team-content-container {
    margin-top: 80px;
    border-top: solid 1px #b4bac2;
    background: url(../assets/img/white-background.svg) no-repeat;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    min-height: 382px;
    width: 100%;
    align-items: center;
}
@media (max-width: 991px) {
    .team-content-container {
        max-width: 100%;
        padding-left: 0px;
        padding-bottom: 50px;
    }
}
.team-background-image {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.team-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    font: 600 33px / 120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 55px;
    margin-bottom: 0px;
}
.description-container {
    align-items: center;
    display: flex;
    margin-top: 14px;
    flex-direction: column;
    font-size: 16px;
    padding: 0 60px 80px;
    width: 50%;
}
@media (max-width: 991px) {
    .description-container {
        max-width: 100%;
        padding: 0 20px;
    }
}
.description-text {
    font-family: Poppins, sans-serif;
    color: var(--Gray-Dark-Medium, #3e404d);
    font-weight: 400;
    text-align: center;
    justify-content: center;
}
@media (max-width: 991px) {
    .description-text {
        max-width: 100%;
    }
}
.join-button {
    font-family: Poppins, sans-serif;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: var(--Secondary-1, #646aff);
    align-self: center;
    margin-top: 33px;
    color: #fff;
    font-weight: 500;
    padding: 16px 38px;
    min-width: 183px;
    height: 56px;
    text-align: center;
}

.intro-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (max-width: 680px) {
    .intro-section {
    }
}

.intro-container {
    justify-content: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--Gray-Dark-Medium, #3e404d);
}
@media (max-width: 991px) {
    .intro-container {
        max-width: 100%;
    }
}
.intro-header {
    display: flex;
    width: 100%;
    flex-direction: column;
}
@media (max-width: 991px) {
    .intro-header {
        max-width: 100%;
    }
}
.intro-title {
    width: 100%;
    font: 600 40px/100% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 70px;
}
@media (max-width: 991px) {
    .intro-title {
        max-width: 100%;
    }
}
.intro-description {
    margin-top: 8px;
    width: 53%;
    font: 400 16px Poppins, sans-serif;
    line-height: 24px;
}
@media (max-width: 991px) {
    .intro-description {
        max-width: 100%;
    }
}
.team-section {
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    margin-top: 53px;
    width: 100%;
}
@media (max-width: 680px) {
    .team-section {
    }
}
.team-container {
    gap: 31px;
    display: flex;
}

.team-member-card {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    max-width: 600px;
    color: var(--Gray-Dark-Medium, #3e404d);
}
@media (max-width: 991px) {
    .team-member-card {
        width: 100%;
        margin-top: 32px;
    }
}
.team-member-card > figure {
    margin: 0;
    /* padding: 30px; */
    border: 1px solid rgba(180, 186, 194, 1);
    /* background-color: var(--Background, #f5f5f5); */
}
.profile-img {
    aspect-ratio: 1.15;
    object-fit: cover;
    object-position: center;
    width: 100%;
}
.team-member-header {
    display: flex;
    flex-direction: column;
}

.team-member-info {
    display: flex;
    flex-direction: column;
}

.team-member-name {
    font: 600 26px Poppins, sans-serif;
    margin: 0;
    margin-top: 23px;
    margin-left: 19px;
}
.team-member-title {
    font: 400 21px Poppins, sans-serif;
    margin-top: 8px;
    margin-left: 19px;
}
.team-member-description {
    margin-top: 10px;
    margin-left: 19px;
    margin-right: 19px;
    font: 400 16px Poppins, sans-serif;
    line-height: 24px;
}

.partner-logo {
    display: flex;
    gap: 20px;
    margin-left: 19px;
    margin-top: 72px;
    margin-bottom: 29px;
}
.partner-logo > img {
    width: 37px;
}

.nav-link {
    color: inherit;
    text-decoration: none;
}

@media (max-width: 991px) {
    .intro-description {
        width: 75%;
    }

    .team-container {
        flex-direction: column;
        /* align-items: stretch; */
        align-items: center;
        gap: 0px;
    }

    .team-member-header {
        flex-direction: row;
        margin: 20px;
    }

    .profile-img {
        width: 200px;
        height: 200px;
        border-radius: 200px;
    }

    .partner-logo {
        margin-top: 20px;
    }

    .team-member-name {
        margin-top: 58px;
        margin-left: 20px;
        word-spacing: 9999999px; /* break sentence into words in separate lines */
        line-height: 30px;
    }

    .team-member-title {
        margin-top: 16px;
    }
}

@media (max-width: 460px) {
    .team-member-header {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }

    .team-member-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .team-member-name {
        margin-top: 20px;
        margin-left: 0px;
        word-spacing: initial;
    }
}
