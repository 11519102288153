.budget-estimate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.main-estimate-container {
    display: flex;
    padding: 70px 160px;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    background-color: var(--Background, #f5f5f5);
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--Background, #f5f5f5);
    padding: 0px 0px 69px;
    border-top: 1px solid var(--Gray-Light-Medium, #b4bac2);
    width: 100%;
}

.section-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1080px;
    margin-top: 65px;
}
@media (max-width: 991px) {
    .section-wrapper {
        max-width: 100%;
        margin-top: 40px;
    }
}
.estimates-section-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    align-self: center;
    font: 600 33px/120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 56px;
    margin-bottom: 64px;
}

.how-it-works-section {
    display: flex;
    justify-content: center;
    background-color: var(--Background, #f5f5f5);
    /* padding: 55px 60px 68px; */
    border-top: 1px solid var(--Gray-Light-Medium, #b4bac2);
    font-size: 33px;
    font-weight: 600;
    color: var(--Gray-Dark-Medium, #3e404d);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.process-image {
    max-width: 90vw;
}

.process-image-mobile {
    display: none;
}

.how-it-works-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1120px;
}

.est-how-it-works-title {
    font: 600 33px / 120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 56px;
    margin-bottom: 0px;
}

.estimates-how-it-works-image {
    width: 100%;
    margin-top: 45px;
    margin-bottom: 79px;
}

.estimates-how-it-works-image-mobile {
    display: none;
}

@media (max-width: 991px) {
    .how-it-works-container {
        width: 100%;
    }

    .process-image {
        display: none;
    }

    .process-image-mobile {
        display: block;
        width: 50vw;
        min-width: 350px;
    }

    .estimates-how-it-works-image {
        display: none;
    }

    .estimates-how-it-works-image-mobile {
        display: block;
        margin-top: 45px;
        margin-bottom: 79px;
    }
}

@media (max-width: 680px) {
    .page-wrapper {
        height: min-content;
        padding: 30px 0px 40px 0px;
    }
    .process-image {
        width: 90vw;
        /* width: 290px;
        height: 575px; */
    }

    .how-it-works-section {
        /* width: 680px; */
        height: min-content;
        padding: 30px 0px 40px 0px;
    }

    .estimates-how-it-works-image {
        max-width: 680px;
        width: unset !important;
    }

    .section-title {
        font-size: 26px;
        margin-left: 0;
        text-align: center;
    }

    .est-how-it-works-title {
        font-size: 26px;
    }
}
