.container {
    border: 1px solid rgba(180, 186, 194, 1);
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.inner-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 64px;
}

.nav-bar {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 60px;
}

.nav-content {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.nav-items {
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 64px;
}

@media (max-width: 991px) {
    .inner-container {
        max-width: 100%;
    }
}

@media (max-width: 991px) {
    .nav-bar {
        padding: 0 20px;
    }
}

@media (max-width: 991px) {
    .nav-content {
        max-width: 100%;
    }
}

@media (max-width: 991px) {
    .nav-items {
        flex-wrap: wrap;
    }
}
.logo-section {
    display: flex;
    gap: 20px;
    font-size: 14px;
    color: var(--Gray-Dark-Medium, #3e404d);
    font-weight: 500;
    white-space: nowrap;
    justify-content: space-between;
}
@media (max-width: 991px) {
    .logo-section {
        flex-wrap: wrap;
        white-space: initial;
    }
}
.logo {
    aspect-ratio: 1.33;
    object-fit: auto;
    object-position: center;
    width: 67px;
    margin: auto 0;
}
.nav-links {
    display: flex;
    gap: 20px;
    align-items: center;
}
@media (max-width: 991px) {
    .nav-links {
        flex-wrap: wrap;
        white-space: initial;
    }
}
.nav-item {
    font-family: Poppins, sans-serif;
    justify-content: center;
    padding: 16px;
    white-space: initial;
}

.login-section {
    justify-content: end;
    display: flex;
    gap: 16px;
}

.lang-switch {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
.lang-icons {
    justify-content: center;
    display: flex;
    gap: 6px;
    padding: 9px 0;
}

.lang-label {
    color: var(--Gray-Dark-Medium, #3e404d);
    font: 500 14px Poppins, sans-serif;
}
.nav-icon {
    aspect-ratio: 1.61;
    object-fit: auto;
    object-position: center;
    width: 13px;
    fill: var(--Gray-Dark-Medium, #3e404d);
    stroke-width: 0.4px;
    stroke: var(--Background, #fff);
    margin: auto 0;
}

.columns {
    display: flex;
    gap: 20px;
}
@media (max-width: 991px) {
    .columns {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}
.column {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin-left: 0px;
}
@media (max-width: 991px) {
    .column {
        width: 100%;
    }
}

.learn-more {
    display: flex;
    padding-right: 12px;
    gap: 10px;
    font-size: 16px;
    color: var(--Secondary, #5d6ff1);
    font-weight: 500;
    line-height: normal;
}
.learn-more a {
    color: inherit;
    text-decoration: none;
}

.learn-more-text {
    font-family: Poppins, sans-serif;
}
.learn-more-icon {
    aspect-ratio: 0.6;
    object-fit: auto;
    object-position: center;
    width: 6px;
    fill: var(--Secondary, #5d6ff1);
    margin: auto 0;
}
/* new header end */

.main-wrapper {
    border-bottom: 1px solid rgba(180, 186, 194, 1);
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 64px;
    min-height: 64px;
}

@media (max-width: 680px) {
    .main-wrapper {
        width: max-content;
    }
}

.header-wrapper {
    display: flex;
    max-width: 1120px;
    width: 100%;
    height: 64px;
    /* padding: 0 20px; */
    min-height: 64px;
}

@media (max-width: 991px) {
    .header-wrapper {
        max-width: 100%;
    }
}
.header-inner-wrapper {
    align-items: center;
    display: flex;
    width: 100%;
    height: 64px;
    justify-content: center;
    /* padding: 0 160px; */
}
@media (max-width: 1140px) {
    .header-inner-wrapper {
        padding: 0 20px;
    }
}
.header-content-wrapper {
    display: flex;
    width: 100%;
    height: 64px;
    gap: 35px;
}
@media (max-width: 991px) {
    .header-content-wrapper {
        max-width: 100%;
    }
}
.logo-wrapper {
    display: flex;
    gap: 20px;
    font-size: 14px;
    color: var(--Gray-Dark-Medium, #3e404d);
    font-weight: 500;
    white-space: nowrap;
    justify-content: space-between;
}
@media (max-width: 991px) {
    .logo-wrapper {
        flex-wrap: wrap;
        white-space: initial;
    }
}
.logo {
    aspect-ratio: 1.33;
    object-fit: auto;
    object-position: center;
    width: 67px;
    margin: auto 0;
    margin-top: 7px;
}
.menu-wrapper {
    justify-content: end;
    display: flex;
    gap: 20px;
}
@media (max-width: 991px) {
    .menu-wrapper {
        flex-wrap: wrap;
        white-space: initial;
    }
}
.menu-item {
    cursor: pointer;
    font-family: Poppins, sans-serif;
    justify-content: center;
    padding: 20px 16px 12px 18px;
    height: 64px;
    display: flex;
    align-items: center;
    border-bottom: 7px solid transparent;
    font-size: 14px;
    font-weight: 500;
    color: var(--Gray-Dark-Medium, #3e404d);
}

.menu-item.active {
    border-bottom: 7px solid var(--Secondary-2, #8388ff);
}
@media (max-width: 991px) {
    .menu-item {
        white-space: initial;
    }
}
.dropdown {
    justify-content: center;
    display: flex;
    gap: 6px;
}
.menu-item-title {
    font-family: Poppins, sans-serif;
}
.dropdown-icon {
    rotate: -90deg;
    transition: 0.3s;
}

.dropdown-icon-up {
    rotate: 0deg;
    transition: 0.3s;
}
.login-wrapper {
    display: flex;
}
.language-switcher-wrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.language-switcher-inner-wrapper {
    justify-content: center;
    display: flex;
    padding: 9px 0;
    /* gap: 5px; */
    gap: 6px;
}

.content-wrapper {
    justify-content: center;
    width: 100%;
}

.products-menu-wrapper {
    height: 130%;
}

.content-section {
    display: none;
    position: relative;
    margin-bottom: -270px;
}

.content-highlight {
    display: flex;
    justify-content: center;
    position: relative;
}

.columns-wrapper {
    gap: 20px;
    display: flex;
}

.block {
    display: flex;
    line-height: normal;
    margin-left: 0px;
}

.learn-more-block {
    border: 1px solid rgba(180, 186, 194, 1);
    background-color: #f5f5f5;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    padding: 30px 30px 36px 30px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    flex: 1 0 0;
    height: 270px;
    width: 360px;
}

.learn-more-block:hover {
    background-color: #e6e6e6;
}

.title-description-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    color: var(--Gray-Dark-Medium, #3e404d);
}
.title {
    height: 48px;
    font: 600 20px/24px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
.description {
    margin-top: 24px;
    font: 400 14px/18px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
.learn-more-wrapper {
    display: flex;
    padding-right: 12px;
    gap: 10px;
    font-size: 16px;
    color: var(--Secondary, #5d6ff1);
    font-weight: 500;
}
.learn-more {
    font-family: Poppins, sans-serif;
}
.arrow-icon {
    margin-left: -10px;
}

.nav-link {
    color: inherit;
    text-decoration: none;
}

/* lang switch */
.language-container {
    /* height: 64px;
    max-width: 64px; */
    display: flex;
}
.language-switcher-container {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    padding-right: 16px;
    padding-left: 33px;
    padding-top: 1px;
    /* border-bottom: 7px solid transparent; */
}

.language-switcher-container.active {
    border-bottom: 7px solid var(--Secondary-2, #8388ff);
    padding-top: 12px;
    padding-bottom: 4px;
}

.lang-icon {
    width: 22px;
}
.lang-container {
    width: 150px;
    height: 140px;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid var(--Gray-Light-Medium, #b4bac2);
    background: var(--Background, linear-gradient(0deg, rgba(233, 235, 246, 0.03) 0%, rgba(233, 235, 246, 0.03) 100%), #f5f5f5);
}

.div-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    padding: 13px 20px 13px 21px;
    position: relative;
    flex: 0 0 auto;
}

.text-wrapper {
    width: max-content;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0;
    line-height: normal;
    color: var(--Gray-Medium, #83858c);
    font-family: Poppins;
}

.lang-items-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 21px;
    width: 130px;
    cursor: pointer;
}

.lang-item {
    color: var(--Gray-Dark-Medium, #3e404d);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* width: 24px; */
}

.lang-button-checked {
    position: relative;
    width: 18px;
    height: 18px;
    background-color: var(--background);
    border-radius: 4px;
    border: 1px solid;
    border-color: var(--graylight-medium);
}

.lang-checkbox {
    position: relative;
    width: 12px;
    height: 12px;
    top: 2px;
    left: 2px;
    background-color: var(--graydark-medium);
    border-radius: 1.5px;
    border: 1px solid;
    border-color: var(--graydark-medium);
}

.login-wrapper .menu-item {
    width: max-content;
}

@media (max-width: 1420px) {
    .content-section {
        /* display: flex;
    justify-content: center;
    position: relative; */
        top: 63px;
        position: absolute;
        flex-direction: column;
        margin-left: -400px;
        z-index: 10;
    }

    .block {
        flex-direction: column;
    }
}

@media (max-width: 1200px) {
    .lang-container {
        right: 10px;
    }
}

@media (max-width: 991px) {
    .content-wrapper {
        max-width: 100%;
    }

    .blocks-wrapper {
        gap: 0px;
    }

    .column {
        width: 100%;
    }

    .learn-more-block {
        padding: 20px;
    }

    .content-section {
        margin-left: -200px;
    }
}
