* {
    box-sizing: border-box;
}

:root {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}
@media (max-width: 680px) {
    #root {
        /* overflow: auto; */
    }
}

body {
    margin: 0;
}
