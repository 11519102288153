.product-link {
    text-decoration: none;
    color: red;
}
.products-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    color: white;
}

.modules-section {
    border-color: rgba(180, 186, 194, 1);
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    background-color: var(--Background, #f5f5f5);
    display: flex;
    width: 100%;
    flex-direction: column;
}

.modules-title {
    color: #414042;
    text-align: center;
    align-self: center;
    padding: 50px;
    font: 600 33px/120% Poppins, sans-serif;
    border-top: 1px solid var(--Gray-Light-Medium, #b4bac2);
    width: 100%;
}

.modules-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.modules-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.module-content {
    display: flex;
    padding: 40px;
    border: 1px solid var(--Gray-Light-Medium, #b4bac2);
    max-width: 720px;
    height: 310px;
    color: var(--Gray-Dark-Medium, #3e404d);
    text-decoration: none;
}

.module-content.pl-PL {
    height: 350px;
}

.module-content.genspace:hover {
    background: linear-gradient(0deg, rgba(67, 67, 72, 0.78) 0%, rgba(67, 67, 72, 0.78) 100%),
        url(../assets/img/genspace-background.svg) lightgray 50% / cover no-repeat;
    color: white;
}
.module-content.budget-estimate:hover {
    background: linear-gradient(0deg, rgba(67, 67, 72, 0.78) 0%, rgba(67, 67, 72, 0.78) 100%),
        url(../assets/img/budget-estimate-background.svg) lightgray 50% / cover no-repeat;
    color: white;
}
.module-content.leasing:hover {
    background: linear-gradient(0deg, rgba(67, 67, 72, 0.78) 0%, rgba(67, 67, 72, 0.78) 100%),
        url(../assets/img/leasing-background.svg) lightgray 50% / cover no-repeat;
    color: white;
}
.module-content.management:hover {
    background: linear-gradient(0deg, rgba(67, 67, 72, 0.78) 0%, rgba(67, 67, 72, 0.78) 100%),
        url(../assets/img/management-background.svg) lightgray 50% / cover no-repeat;
    color: white;
}

.module-left {
    width: 50%;
}

.module-image {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
}

.module-image img {
    aspect-ratio: 1.67;
    object-fit: auto;
    object-position: center;
    width: 100%;
}
.module-right {
    width: 41%;
    margin-left: 20px;
}

.module-text {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.module-description {
    flex-direction: column;
    display: flex;
}
.module-title {
    font: 600 30px/36px Poppins, sans-serif;
}
.module-info {
    margin-top: 20px;
    font: 400 14px/18px Poppins, sans-serif;
}
.module-learn-more {
    justify-content: center;
    margin-top: 15px;
    gap: 10px;
    font-size: 20px;
    color: var(--Secondary, #5d6ff1);
    font-weight: 500;
    display: flex;
}

.learn-more-icon {
    aspect-ratio: 0.6;
    object-fit: auto;
    object-position: center;
    width: 6px;
    fill: var(--Secondary, #5d6ff1);
    margin: auto 0;
}

@media (max-width: 1200px) {
    .module-content {
        height: 350px;
        gap: 4vw;
    }

    .module-content.pl-PL {
        height: 400px;
    }

    .modules-grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

@media (max-width: 991px) {
    .module-text {
        margin-top: 40px;
        min-width: 180px;
    }

    .module-title {
        font-size: 24px;
        line-height: 32px;
    }

    .module-image {
        margin-top: 40px;
    }

    .module-right {
        width: 100%;
        margin-left: 0px;
    }

    .modules-list {
        max-width: 100%;
    }

    .module-left {
        width: 60%;
        min-width: 280px;
    }
}

@media (max-width: 680px) {
    .module-learn-more {
        height: 56px;
        /* max-width: calc(min(288px, 70vw));
        padding: 16px 38px; */
        border-radius: 15px;
        background: var(--Secondary-1, #646aff);
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        line-break: anywhere;
    }

    .learn-more-icon {
        display: none;
    }

    .module-content {
        height: fit-content;
    }

    .modules-section {
        width: unset;
    }

    .modules-grid-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .modules-title {
        font-size: 24px;
    }

    .modules-grid-container {
        gap: 0;
    }

    .module-content.pl-PL {
        height: initial;
    }
}

@media (max-width: 580px) {
    .module-content {
        height: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
