.clients-section {
    align-items: center;
    border: 1px solid(180, 186, 194, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    font-size: 33px;
    line-height: 120%;
}

.clients-container {
    display: flex;
    width: 100%;
    max-width: 1440px;
    padding: 50px 160px 60px 160px;
    flex-direction: column;
    border-top: 1px solid var(--Gray-Light-Medium, #b4bac2);
}

.clients-title {
    font-family: Poppins, sans-serif;
    color: var(--Gray-Dark-Medium, #3e404d);
    text-align: center;
    margin-bottom: 50px;
    font-weight: 600;
}

.clients-slider-container {
    display: flex;
    overflow: hidden;
}

.slider-container {
    display: flex;
    transition: transform 0.5s ease-out;
    border-radius: 16px;
    background: var(--Basic-White, #fff);
    width: 200px;
    height: 130px;
    gap: 25px;
}

.slider-container img {
    width: 100%;
    flex-shrink: 0;
    border-radius: 16px;
}

@media (max-width: 991px) {
    .clients-section {
        max-width: 100%;
        padding: 0 20px;
    }

    .clients-section {
        font-size: 24px;
    }

    .clients-container {
        max-width: 100%;
        padding: 50px 40px 60px 40px;
    }
}
