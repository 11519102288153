.button {
    all: unset;
    align-items: center;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    height: 56px;
    justify-content: center;
    padding: 16px 42px;
    position: relative;
    /* width: 183px; */
    cursor: pointer;
}

@media (max-width: 680px) {
    .button {
        border-radius: 12px;
        height: 56px;
        width: calc(100vw - 55px);
        padding: 16px 38px;
    }
}

.button .text-wrapper {
    color: #ffffff;
    font-family: 'Poppins-Medium', Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.button.disabled {
    background-color: #b4bac2;
}

.button.pressed {
    background-color: #4a55cc;
}

.button.primary {
    background-color: #646aff;
}

.button.hover {
    background-color: #7e85ff;
}
