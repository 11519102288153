@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
header {
    display: flex;
    flex-direction: column;
}

.nav-left {
    display: flex;
    gap: 20px;
    font-size: 14px;
    color: var(--Gray-Dark-Medium, #3e404d);
    font-weight: 500;
    white-space: nowrap;
}
@media (max-width: 991px) {
    .nav-left {
        flex-wrap: wrap;
        white-space: initial;
    }
}
.logo {
    aspect-ratio: 1.33;
    object-fit: auto;
    object-position: center;
    width: 67px;
    margin: auto 0;
    margin-top: 7px;
}
nav {
    justify-content: end;
    display: flex;
    gap: 20px;
}
@media (max-width: 991px) {
    nav {
        flex-wrap: wrap;
        white-space: initial;
    }
}
.nav-item {
    font-family: Poppins, sans-serif;
    justify-content: center;
    padding: 16px;
}
@media (max-width: 991px) {
    .nav-item {
        white-space: initial;
    }
}
.language-switcher {
    display: flex;
    gap: 6px;
    padding: 16px;
}
@media (max-width: 991px) {
    .language-switcher {
        white-space: initial;
    }
}
.dropdown-indicator {
    aspect-ratio: 1.61;
    object-fit: auto;
    object-position: center;
    width: 13px;
    fill: var(--Gray-Dark-Medium, #3e404d);
    stroke-width: 0.4px;
    stroke: var(--Background, #fff);
    margin: auto 0;
}
.login-btn {
    justify-content: center;
    color: var(--Gray-Dark-Medium, #3e404d);
    white-space: nowrap;
    padding: 16px;
    font: 500 14px/150% Poppins, sans-serif;
}
@media (max-width: 991px) {
    .login-btn {
        white-space: initial;
    }
}
.header-right {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
.header-controls {
    justify-content: center;
    display: flex;
    gap: 6px;
    padding: 9px 0;
}
.language-selection-container {
    justify-content: center;
    display: flex;
    gap: 8px;
    align-items: center;
}
.language-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
    border: 1px solid rgba(62, 64, 77, 1);
    border-radius: 50%;
}
.language-current {
    border: 1px solid rgba(62, 64, 77, 1);
    background-color: #3e404d;
    height: 22px;
}
.additional-languages {
    font: 500 14px Poppins, sans-serif;
    color: var(--Gray-Dark-Medium, #3e404d);
}
.cookie-icon {
    width: 13px;
    fill: var(--Gray-Dark-Medium, #3e404d);
    stroke-width: 0.4px;
    stroke: var(--Background, #fff);
    margin: auto 0;
}
/* section {
    border-color: rgba(180, 186, 194, 1);
    border-style: solid;
    border-right-width: 1px;
    border-left-width: 1px;
    background-color: #f5f5f5;
    display: flex;
    width: 100%;
    flex-direction: column;
}
*/

@media (max-width: 680px) {
    section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.benefits-section {
    border-color: rgba(180, 186, 194, 1);
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    background-color: var(--Background, #f5f5f5);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 1px 0 19px;
}
@media (max-width: 991px) {
    .benefits-section {
        max-width: 100%;
        padding-right: 20px;
    }
}
.benefits-image {
    aspect-ratio: 3.03;
    object-fit: auto;
    object-position: center;
    width: 100%;
    max-width: 1279px;
}
@media (max-width: 991px) {
    .benefits-image {
        max-width: 100%;
    }
}
.segment-container {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 55px 60px;
}
@media (max-width: 991px) {
    .segment-container {
        max-width: 100%;
        padding: 0 20px;
    }
}
.segment-content {
    display: flex;
    width: 100%;
    max-width: 1120px;
    flex-direction: column;
}
@media (max-width: 991px) {
    .segment-content {
        max-width: 100%;
    }
}
.segment-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    align-self: center;
    font: 600 33px/120% Poppins, sans-serif;
}
.segment-list {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
}
@media (max-width: 991px) {
    .segment-list {
        max-width: 100%;
        margin-top: 40px;
    }
}
.segment-items {
    display: flex;
    gap: 0px;
    font-size: 20px;
    color: var(--Gray-Medium, #83858c);
    font-weight: 500;
    text-align: center;
}
@media (max-width: 991px) {
    .segment-items {
        flex-wrap: wrap;
    }
}
.segment-item {
    font-family: Poppins, sans-serif;
    justify-content: center;
    border: 1px solid rgba(100, 106, 255, 1);
    background-color: var(--Secondary-1, #646aff);
    color: var(--Basic-White, #fff);
    padding: 19px 25px;
}
@media (max-width: 991px) {
    .segment-item {
        padding: 0 20px;
    }
}

.right-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
}
@media (max-width: 991px) {
    .right-column {
        width: 100%;
    }
}
.cta-section {
    align-items: center;
    border-color: rgba(180, 186, 194, 1);
    border-style: solid;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    background-color: var(--Background, #f5f5f5);
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 70px 60px;
}
@media (max-width: 991px) {
    .cta-section {
        max-width: 100%;
        padding: 0 20px;
    }
}
.cta-container {
    width: 100%;
    max-width: 1120px;
}
@media (max-width: 991px) {
    .cta-container {
        max-width: 100%;
    }
}
.cta-content {
    gap: 20px;
    display: flex;
}
@media (max-width: 991px) {
    .cta-content {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}
.cta-text {
    display: flex;
    flex-direction: column;
    color: var(--Gray-Dark-Medium, #3e404d);
}
@media (max-width: 991px) {
    .cta-text {
        max-width: 100%;
        margin-top: 40px;
    }
}
.cta-title {
    font: 600 40px/100% Poppins, sans-serif;
}
@media (max-width: 991px) {
    .cta-title {
        max-width: 100%;
    }
}
.cta-subtitle {
    margin-top: 40px;
    font: 400 16px Poppins, sans-serif;
}
@media (max-width: 991px) {
    .cta-subtitle {
        max-width: 100%;
    }
}
.form-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
}
@media (max-width: 991px) {
    .form-column {
        width: 100%;
    }
}
.form-container {
    display: flex;
    flex-grow: 1;
    padding-top: 80px;
    flex-direction: column;
}
@media (max-width: 991px) {
    .form-container {
        max-width: 100%;
        margin-top: 40px;
    }
}
.form-content {
    display: flex;
    flex-direction: column;
}
@media (max-width: 991px) {
    .form-content {
        max-width: 100%;
    }
}
.form-fields {
    display: flex;
    gap: 20px;
    font-size: 14px;
    color: var(--Gray-Dark-Medium, #3e404d);
    font-weight: 400;
    line-height: 128%;
}
@media (max-width: 991px) {
    .form-fields {
        flex-wrap: wrap;
        white-space: initial;
    }
}
.input-field-container {
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}
@media (max-width: 991px) {
    .input-field-container {
        white-space: initial;
    }
}
.input-field {
    font-family: Poppins, sans-serif;
    align-items: start;
    border-radius: 8px;
    border: 1px solid rgba(180, 186, 194, 1);
    background: var(--Background, #f5f5f5);
    justify-content: center;
    padding: 8px 16px;
}
@media (max-width: 991px) {
    .input-field {
        padding-right: 20px;
    }
}
.additional-fields {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    font-size: 14px;
    color: var(--Gray-Dark-Medium, #3e404d);
    font-weight: 400;
    text-align: center;
    line-height: 128%;
    justify-content: center;
}
@media (max-width: 991px) {
    .additional-fields {
        max-width: 100%;
        white-space: initial;
    }
}
.textarea-container {
    display: flex;
    flex-direction: column;
    -content: center;
}
@media (max-width: 991px) {
    .textarea-container {
        max-width: 100%;
        white-space: initial;
    }
}
.textarea-field {
    font-family: Poppins, sans-serif;
    align-items: start;
    border-radius: 8px;
    border: 1px solid rgba(180, 186, 194, 1);
    background: var(--Background, #f5f5f5);
    justify-content: center;
    padding: 12px 16px;
}
@media (max-width: 991px) {
    .textarea-field {
        white-space: initial;
        padding-right: 20px;
    }
}
.form-actions {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
}
@media (max-width: 991px) {
    .form-actions {
        max-width: 100%;
    }
}
.checkbox-container,
.checkbox-container-2 {
    display: flex;
    gap: 18px;
}
@media (max-width: 991px) {
    .checkbox-container,
    .checkbox-container-2 {
        flex-wrap: wrap;
    }
}
.checkbox-field {
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.checkbox {
    border-radius: 3.667px;
    border: 1px solid rgba(180, 186, 194, 1);
    background: var(--Background, #f5f5f5);
    width: 22px;
    height: 22px;
}
.checkbox-label,
.checkbox-label-2 {
    color: var(--Gray-Dark-Medium, #3e404d);
    font: 400 14px/128% Poppins, sans-serif;
}
@media (max-width: 991px) {
    .checkbox-label,
    .checkbox-label-2 {
        max-width: 100%;
    }
}
.submit-btn {
    justify-content: center;
    border-radius: 15px;
    background-color: var(--Secondary-1, #646aff);
    align-self: start;
    margin-top: 30px;
    color: #fff;
    padding: 15px 32px;
    font: 500 16px Poppins, sans-serif;
}
@media (max-width: 991px) {
    .submit-btn {
        padding: 0 20px;
    }
}

.products-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 39%;
}
@media (max-width: 991px) {
    .products-column {
        width: 100%;
    }
}
.products-list {
    margin-top: 40px;
    flex-grow: 1;
    flex-direction: column;
}
.products-title {
    justify-content: center;
    white-space: nowrap;
    font: 500 20px Poppins, sans-serif;
}
@media (max-width: 991px) {
    .products-title {
        white-space: initial;
    }
}
.product {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
}
.product-item {
    font-family: Poppins, sans-serif;
    justify-content: center;
    padding: 10px 0;
}
.company-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 32%;
    margin-left: 20px;
}
@media (max-width: 991px) {
    .company-column {
        width: 100%;
    }
}
.company-list {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    color: var(--Gray-Light-Medium, #b4bac2);
}
.company-title {
    justify-content: center;
    white-space: nowrap;
    font: 500 20px Poppins, sans-serif;
}
@media (max-width: 991px) {
    .company-title {
        white-space: initial;
    }
}
.company-item {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
}
.company-link {
    font-family: Poppins, sans-serif;
    justify-content: center;
    padding: 10px 0;
}
.help-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 28%;
    margin-left: 20px;
}
@media (max-width: 991px) {
    .help-column {
        width: 100%;
    }
}
.help-list {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    color: var(--Gray-Light-Medium, #b4bac2);
}
.help-title {
    justify-content: center;
    white-space: nowrap;
    font: 500 20px Poppins, sans-serif;
}
@media (max-width: 991px) {
    .help-title {
        white-space: initial;
    }
}
.help-item {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
}
.help-link {
    font-family: Poppins, sans-serif;
    justify-content: center;
    padding: 10px 0;
}

.footer-bottom-container {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    font-size: 16px;
    color: var(--Gray-Light-Medium, #b4bac2);
    font-weight: 400;
    text-align: center;
    padding: 36px 60px;
}
@media (max-width: 991px) {
    .footer-bottom-container {
        max-width: 100%;
        padding: 0 20px;
    }
}
.footer-bottom-links {
    display: flex;
    gap: 12px;
}
@media (max-width: 991px) {
    .footer-bottom-links {
        flex-wrap: wrap;
    }
}
.privacy-policy {
    font-family: Poppins, sans-serif;
    justify-content: center;
    padding: 16px 0;
}
.footer-rights {
    font-family: Poppins, sans-serif;
    justify-content: center;
    padding: 16px 0;
}
