.footer-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.footer-container {
    background-color: #2c2c2c;
    width: 100%;
    /* padding: 50px 160px; */
    max-width: 1440px;
    color: var(--Gray-Light-Medium, #b4bac2);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    /* max-width: 1210px; */
    margin-top: 51px;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 57px;
}

.footer-content {
    display: flex;
    flex-direction: column;
}

.footer-main {
    display: grid;
    grid-template-columns: repeat(5, 0fr);
    column-gap: 40px;
}

.footer-col {
    flex: 1;
    /* margin: 0 20px; */
}

.footer-col:nth-child(1) {
    grid-area: 1 / 1;
    width: 235px;
}

.footer-col:nth-child(2) {
    grid-area: 1 / 2;
    width: 169px;
}

.footer-col:nth-child(3) {
    grid-area: 1 / 3;
    width: 140px;
}

.footer-col:nth-child(4) {
    grid-area: 1 / 4;
    width: 146px;
}

.footer-col:nth-child(5) {
    grid-area: 1 / 5;
    width: 270px;
}

.info-column {
    color: #b4bac2;
}

.info-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 26px;
}

.info-items {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.info-item {
    font-size: 16px;
    /* margin: 10px 0; */
}

.company-info {
    display: flex;
    flex-direction: column;
}
.linkedin-img {
    width: 37px;
    height: 37px;
    align-self: flex-start;
    margin-top: 23px;
}

.company-logo img {
    width: 90px;
    margin-left: 8px;
    margin-top: 2px;
}

.company-details {
    /* margin-left: 25px; */
    margin-top: 22px;
}

.contact {
    display: flex;
    align-items: flex-start;
    margin-top: 18px;
}

.contact-email {
    margin-top: 1px;
}

.contact img {
    width: 24px;
    margin-right: 15px;
}

.newsletter {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.newsletter-label {
    font-size: 16px;
    color: #b4bac2;
    margin-bottom: 24px;
}

.newsletter-form {
    display: flex;
    background-color: #b4bac2;
    border-radius: 8px;
}

.newsletter-input {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: #b4bac2;
    margin-left: 20px;
}

.newsletter-button {
    height: 50px;
}

.newsletter-button img {
    width: 42px;
    margin-top: 3px;
    margin-right: 4px;
}

.footer-bottom {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #b4bac2;
    text-align: center;
    width: 100%;
    /* max-width: 1440px; */
    border-top: 1px solid rgba(131, 133, 140, 1);

    gap: 65px;
    padding-bottom: 49px;
    padding-top: 53px;
}

.footer-bottom div {
    margin: 0 0px;
}

.nav-link,
.login-link {
    color: inherit;
    text-decoration: none;
}

.footer-link-mail {
    text-decoration: none;
    color: var(--Gray-Light-Medium, #b4bac2);
}

/* @media (max-width: 680px) {
    .footer-container {
        padding: 20px;
    }
}

@media (max-width: 991px) {
    .footer-bottom {
        padding: 0 20px;
    }
} */

@media (max-width: 1252px) {
    .footer-main {
        grid-template-columns: repeat(4, 0fr);
    }

    .footer-col:nth-child(4) {
        width: max-content;
    }

    .footer-col:nth-child(5) {
        grid-area: 2 / 1;
        margin-top: 60px;
    }
}

@media (max-width: 1024px) {
    .footer-main {
        grid-template-columns: repeat(4, 0fr);
        column-gap: 0;
    }

    .footer-col:nth-child(1) {
        grid-area: 1 / 4;
        width: max-content;
    }

    .footer-col:nth-child(2) {
        grid-area: 1 / 2;
        width: 169px;
        padding-right: 40px;
    }

    .footer-col:nth-child(3) {
        grid-area: 1 / 3;
        width: 140px;
        padding-right: 40px;
    }

    .footer-col:nth-child(4) {
        grid-area: 1 / 1;
        width: 146px;
    }

    .footer-col:nth-child(5) {
        grid-area: 2 / 1;
        margin-top: 60px;
    }
}

@media (max-width: 820px) {
    .footer-container {
        margin-top: 40px;
        margin-bottom: 40px;
        align-items: flex-start;
    }

    .footer-content {
        margin-left: 16px;
    }

    .footer-main {
        grid-template-columns: repeat(4, 0fr);
        column-gap: 39px;
    }

    .footer-col:nth-child(1) {
        grid-area: 2 / 1;
        width: max-content;
        margin-top: 29px;
    }

    .footer-col:nth-child(2) {
        grid-area: 1 / 1;
        width: 113px;
        padding-right: 0px;
    }

    .footer-col:nth-child(3) {
        grid-area: 1 / 2;
        width: 120px;
    }

    .footer-col:nth-child(4) {
        grid-area: 1 / 3;
        width: 154px;
        margin-left: 7px;
    }

    .footer-col:nth-child(5) {
        grid-column: 3 / 5;
        margin-top: 45px;
        /* justify-self: end; */
        margin-left: -15px;
    }

    .footer-bottom {
        padding-top: 36px;
        padding-bottom: 38px;
        font-size: 14px;
        font-weight: 400;
        gap: 93px;
    }

    .company-details {
        font-size: 14px;
        margin-top: 13px;
    }

    .contact {
        margin-top: 14px;
    }

    .company-logo img {
        margin-top: 17px;
    }

    .linkedin-img {
        margin-top: 21px;
    }

    .info-items {
        gap: 20px;
    }

    .info-item {
        font-size: 14px;
    }

    .info-title {
        font-size: 18px;
        margin-bottom: 22px;
    }

    .newsletter-label {
        font-size: 14px;
    }

    .newsletter-input {
        margin-left: 52px;
        font-size: 15px;
        font-weight: 500;
        color: black;
        outline: none;
        width: 100%;
    }
}

@media (max-width: 686px) {
    .footer-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0;
    }

    .footer-main {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0;
        row-gap: 60px;
    }

    .footer-col:nth-child(1) {
        grid-area: 3 / 1 / 3 / 3;
        justify-self: center;
    }

    .footer-col:nth-child(2) {
        grid-area: 1 / 1;
    }

    .footer-col:nth-child(3) {
        grid-area: 1 / 2;
    }

    .footer-col:nth-child(4) {
        grid-area: 2 / 1;
        margin-left: 0;
    }

    .footer-col:nth-child(5) {
        grid-area: 4 / 1 / 4 / 3;
        margin-left: 0;
        justify-self: center;
    }
}
