.content {
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
}
@media (max-width: 680px) {
    .content {
    }
}

.root-container {
    display: flex;
    background-color: #f5f5f5;
    max-width: 1440px;
    width: 100%;
}

.footer-wrapper {
    display: flex;
    justify-content: center;
    background-color: #2c2c2c;
}
